import PropTypes from 'prop-types'
import React from 'react'

const Header = props => (
  <header id="header" style={props.timeout ? { display: 'none' } : {}}>
    <div className="logo">
      <span class="icons">
        <a class="icon fa-github" href="https://github.com/achuthrajula"></a>
      </span>
    </div>
    <div className="content">
      <div className="inner">
        <h1>
          hi, I'm <strong style={{ fontSize: '50px' }}>Achuth Rajula</strong>
        </h1>
        <h3 style={{ fontStyle: 'italic' }}>
          An enthusiast and versatile software engineer.
        </h3>
        <h5>
          Currently pursuing Master's in computer software engineering at{' '}
          <a style={{ fontSize: '16px' }} href="https://www.asu.edu/">
            ASU
          </a>
          <h5>
            and interning as a software engineer at{' '}
            <a style={{ fontSize: '16px' }} href="https://www.tesla.com/">
              Tesla
            </a>
          </h5>
        </h5>
      </div>
    </div>
    <nav>
      <ul>
        <li style={{ margin: 'auto' }}>
          <button
            onClick={() => {
              props.onOpenArticle('intro')
            }}
          >
            About Me
          </button>
        </li>
        <li style={{ margin: 'auto' }}>
          <button
            onClick={() => {
              props.onOpenArticle('work')
            }}
          >
            Work
          </button>
        </li>
        <li style={{ margin: 'auto' }}>
          <button
            onClick={() => {
              props.onOpenArticle('about')
            }}
          >
            Resume
          </button>
        </li>
        <li style={{ margin: 'auto' }}>
          <button
            onClick={() => {
              props.onOpenArticle('contact')
            }}
          >
            Contact
          </button>
        </li>
      </ul>
    </nav>
  </header>
)

Header.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool,
}

export default Header
