import PropTypes from 'prop-types'
import React from 'react'
import pic01 from '../images/pic01.jpg'
import pic02 from '../images/tesla.jpeg'
import resume from '../images/resume.png'

class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="intro"
          className={`${this.props.article === 'intro' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">About Me</h2>
          <span className="image main">
            <img
              src={pic01}
              style={{ margin: 'auto', height: '50%', width: '50%' }}
              alt=""
            />
          </span>
          <p>
            I'm a Computer Science graduate with a specialization in Artificial
            Intelligence. As a versatile software engineer, I'm deeply
            passionate about tackling emerging technologies and mastering new
            software tools. With a blend of on-site and remote work experience,
            I excel in managing workloads, making sound technical choices, and
            thriving in collaborative team settings. I'm adept at meeting tight
            deadlines and posses a good sense of humor ;)
          </p>
          <p>
            <ul>
              <li>
                <p>Self taught JS Developer and Python Developer</p>
              </li>
              <li>
                <p>Experienced in MERN Stack and API Development</p>
              </li>
              <li>
                <p>Golang and Blockchain Development enthusiast</p>
              </li>
              <li>
                <p>
                  Experience working with AWS, GCP, Chrome Extension and Alexa
                  Development
                </p>
              </li>
            </ul>
          </p>
          {close}
        </article>

        <article
          id="work"
          className={`${this.props.article === 'work' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Work</h2>
          <span className="image main">
            <img src={pic02} alt="" style={{ height: '200px' }} />
          </span>
          <p style={{ fontSize: 15, justifyContent: 'center' }}>
            <h4>
              Working as a Software Engineer at{' '}
              <a href="https://www.tesla.com/">Tesla</a>
            </h4>
            <br />
            <strong>Engineering Tools, IT Infrastructure Engineering</strong>
            <br /> <br />
            As a Software Engineer [I], I am responsible for developing and
            maintaining the automation pipelines, visualization dashboards,
            utility tools, write clean and efficient code with proper
            documentation. Also working on optimising the existing logic,
            enhancing scalability, modularity, code reusability, maintaining
            technical standards and regularly benchmarking the performance of
            the applications for Engineering Tools.
          </p>
          <p>
            <h4>Some of the projects I worked on:</h4>
            <ul>
              <li>
                <a href="https://taiga-viz.netlify.app/">
                  Taiga Vizulaization Dashboard
                </a>
              </li>
            </ul>
            <ul>
              <li>
                <a href="https://github.com/achuthrajula/Ivarna">Ivarna</a>
              </li>
            </ul>
            <ul>
              <li>
                <a href="https://github.com/achuthrajula/simple-attendance-management">
                  Attendence Management
                </a>
              </li>
            </ul>
            <ul>
              <li>
                <a href="https://github.com/FrontierOne/FireworkAuth">
                  Auth Server
                </a>
              </li>
            </ul>
            {/* <ul>
              <li>
                <a href="https://github.com/achuthrajula/Restaurant-server">
                  Restaurant-website Server{' '}
                </a>
              </li>
            </ul> */}
          </p>
          {close}
        </article>

        <article
          id="about"
          className={`${this.props.article === 'about' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Resume</h2>
          <span>
            <img src={resume} alt="" width="100%" />
          </span>
          <span>
            <p style={{ fontSize: 20 }}>
              Click <a href={resume}>here</a> to download my resume
            </p>
          </span>

          {close}
        </article>

        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Contact</h2>
          <form action="https://formspree.io/xpzynbln" method="POST">
            <div className="field half first">
              <label htmlFor="Name">Name</label>
              <input required type="text" name="Name" id="Name" />
            </div>
            <div className="field half">
              <label htmlFor="Email">Email</label>
              <input required type="email" name="Email" id="Email" />
            </div>
            <div className="field">
              <label htmlFor="Message">Message</label>
              <textarea
                required
                name="Message"
                id="Message"
                rows="4"
              ></textarea>
            </div>
            <ul className="actions">
              <li>
                <input type="submit" value="Send Message" className="special" />
              </li>
              <li>
                <input type="reset" value="Reset" />
              </li>
            </ul>
          </form>
          <ul className="icons">
            <li>
              <a
                href="https://twitter.com/demigod009"
                className="icon fa-twitter"
              >
                <span className="label">Twitter</span>
              </a>
            </li>
            <li>
              <a
                href="https://www.facebook.com/achuth09/"
                className="icon fa-facebook"
              >
                <span className="label">Facebook</span>
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/arajula._/"
                className="icon fa-instagram"
              >
                <span className="label">Instagram</span>
              </a>
            </li>
            <li>
              <a
                href="https://github.com/achuthrajula"
                className="icon fa-github"
              >
                <span className="label">GitHub</span>
              </a>
            </li>
          </ul>
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
